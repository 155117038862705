<template>
	<div>
		<div v-if="isSending" class="message_sending">
			<div class="loader"></div>Nachricht wird gesendet...
		</div>
		<form enctype="multipart/form-data" v-if="!isSending">
			<div id="chat-sendmessage">
				<span class="material-symbols-rounded add_circle" @click="openAttachmentUpload">add_circle</span>
				<span v-if="attachmentCount > 0" class="badge badge-pill badge-info">{{ attachmentCount }}</span>
				<textarea
					v-bind:rows="(windowwidth > 1000 ? '1' : '1')"
					placeholder="Gib eine Nachricht ein..."
					@keydown="typing"
					v-model="content"
					autofocus
				></textarea>
				<div id="chat-textarea-buttons">
					<span class="material-symbols-rounded" @click="sendMessage">send</span>
				</div>
				<input
					type="file"
					name="attachment"
					accept="image/*, video/mp4, video/x-m4v, video/*"
					style="display: none;"
					ref="attachmentUpload"
					@change="filesChanged"
				>
			</div>
			<div id="chat-textarea-priceinfo">
				<div>
					Preis: kostenlos
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import Event from "../event.js";

export default {
	props: [],
	data() {
		return {
			content: null,
			isSending: false,
			attachmentCount: 0,
			showEmojiPicker: false,
			windowwidth: $(window).width(),
			lang: window.Laravel.lang
		};
	},
	mounted() {
		this.autogrow();
	},
	created() {
		window.addEventListener("resize", this.windowresized);
	},
	destroyed() {
		window.removeEventListener("resize", this.windowresized);
	},
	methods: {
		autogrow() {
			if (this.windowwidth > 1000) {
				jQuery('#chat-sendmessage textarea').focus().autogrow(16,90);
			} else {
				jQuery('#chat-sendmessage textarea').focus().autogrow(16,90);
			}
		},
		windowresized(e) {
			this.windowwidth = $(window).width();
			this.autogrow();
		},
		typing(e) {
			if (e.keyCode === 13 && e.shiftKey) {
				e.preventDefault();
				this.sendMessage();
			}
		},
		toggleEmojiPicker() {
			this.showEmojiPicker = !this.showEmojiPicker;
		},
		closeEmojiPicker(outside) {
			this.showEmojiPicker = false;
		},
		selectEmoji(emoji) {
			this.showEmojiPicker = false;
			var cursorPos = jQuery('textarea', this.$el).prop('selectionStart');
			if (!this.content) {
				this.content = emoji.data;
				return true;
			}
			var textBefore = this.content.substring(0,  cursorPos);
			var textAfter  = this.content.substring(cursorPos, this.content.length);

			this.content = textBefore + emoji.data + textAfter;
		},
		filesChanged() {
			this.attachmentCount = !this.$refs.attachmentUpload
				? 0
				: this.$refs.attachmentUpload.files.length;
		},
		openAttachmentUpload() {
			this.$refs.attachmentUpload.click();
		},
		startTypingIndicator() {
			Event.$emit("start_typing_indicator");
		},
		stopTypingIndicator() {
			Event.$emit("stop_typing_indicator");
		},
		sendMessage() {
			if (
				(!this.content || this.content.trim() === "") &&
				this.$refs.attachmentUpload.files.length <= 0
			) {
				return;
			}
			this.isSending = true;

			// for (var i = 0; i < this.$refs.attachmentUpload.files.length; i++) {
			// 	formData.append(
			// 		"attachments[]",
			// 		this.$refs.attachmentUpload.files[i]
			// 	);
			// }
			Event.$emit("added_message", {
                "blur": null,
                "type": null,
                "attachments": null,
                "content": this.content.trim(),
                "from": -1,
                "to": 1,
                "created_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
                "read_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
                "id": (new Date()).getTime()
            });
			if (this.content.toLowerCase().indexOf("strip") > -1) {
                window.jQuery.post('/loggedoutMessagesCodeUsed', {code: 'strip'});
				setTimeout(() => {
					this.startTypingIndicator();
				}, 400);
				setTimeout(() => {
					this.stopTypingIndicator();
					var attachment = {"type":"image","url":"https://chat-attachments.leonie-pur.com/attachments/33a3ba3243c4c77440d0802644e45994.jpg","thumburl":"https://chat-attachments.leonie-pur.com/attachments/33a3ba3243c4c77440d0802644e45994.jpg"};
					Event.$emit("added_message", {
						"attachments": [attachment],
						"content": this.lang.code == 'de' ? 'Die Bluse ist schon mal aus dem Weg 😈 Willst du mehr? Die Rückseite wird richtig heiß! Schreib mir den Code ROCK und ich drehe mich für dich um und lasse dich unter mein Röckchen schauen 😇' : 'The blouse is already out of the way 😈 Do you want more? The back side gets really hot! Write me the code SKIRT and I\'ll turn around for you and let you look under my skirt 😇',
						"from": 1,
						"to": -1,
						"created_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"read_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"id": (new Date()).getTime()
					});
				}, 1900);
			}
			if (this.content.toLowerCase().indexOf("rock") > -1 || this.content.toLowerCase().indexOf("skirt") > -1) {
                window.jQuery.post('/loggedoutMessagesCodeUsed', {code: this.content.toLowerCase().indexOf("rock") > -1 ? 'rock' : 'skirt'});
				setTimeout(() => {
					this.startTypingIndicator();
				}, 400);
				setTimeout(() => {
					this.stopTypingIndicator();
					var attachment = {"type":"image","url":"https://chat-attachments.leonie-pur.com/attachments/78c21f479c2186f96a29bbf5ea7478e7.jpg","thumburl":"https://chat-attachments.leonie-pur.com/attachments/78c21f479c2186f96a29bbf5ea7478e7.jpg"};
					Event.$emit("added_message", {
						"attachments": [attachment],
						"content": this.lang.code == 'de' ? 'Wie gerne ich dich jetzt hier hätte um mir unter mein Röckchen zu greifen 😇 Zieh’ mir also am besten schnell meinen Rock aus mit dem Code POPO 😈' : 'I would really like to have you here now to reach under my skirt 😇 So it\'s best to quickly take off my skirt with the code ASS 😈',
						"from": 1,
						"to": -1,
						"created_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"read_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"id": (new Date()).getTime()
					});
				}, 1900);
			}
			if (this.content.toLowerCase().indexOf("popo") > -1 || this.content.toLowerCase().indexOf("ass") > -1) {
                window.jQuery.post('/loggedoutMessagesCodeUsed', {code: this.content.toLowerCase().indexOf("popo") > -1 ? 'popo' : 'ass'});
				setTimeout(() => {
					this.startTypingIndicator();
				}, 400);
				setTimeout(() => {
					this.stopTypingIndicator();
					var attachment = {"type":"image","url":"https://chat-attachments.leonie-pur.com/attachments/ea2946baeb5b6fd69a2224ee6cdfbce7.jpg","thumburl":"https://chat-attachments.leonie-pur.com/attachments/ea2946baeb5b6fd69a2224ee6cdfbce7.jpg"};
					Event.$emit("added_message", {
						"attachments": [attachment],
						"content": this.lang.code == 'de' ? 'Merk dir die Stellung, die heben wir uns für den Schluss auf, wenn ich ganz nackig bin 😇 Wenn du mir den Code BOOBS schreibst, dann lasse ich gleich noch meinen BH verschwinden 💋' : 'Remember the position, we\'ll save that for the end when I\'m completely naked 😇 If you write me the code BOOBS, I\'ll make my bra disappear right away 💋',
						"from": 1,
						"to": -1,
						"created_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"read_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"id": (new Date()).getTime()
					});
				}, 1900);
			}
			if (this.content.toLowerCase().indexOf("boobs") > -1) {
                window.jQuery.post('/loggedoutMessagesCodeUsed', {code: 'boobs'});
				setTimeout(() => {
					this.startTypingIndicator();
				}, 400);
				setTimeout(() => {
					this.stopTypingIndicator();
					var attachment = {"type":"image","url":"https://chat-attachments.leonie-pur.com/attachments/59126d54a9fad838a776adee19cc465d.jpg","thumburl":"https://chat-attachments.leonie-pur.com/attachments/59126d54a9fad838a776adee19cc465d.jpg"};
					Event.$emit("added_message", {
						"attachments": [attachment],
						"content": this.lang.code == 'de' ? 'Meine Brüste warten schon auf dich um angefasst zu werden 😈 Jetzt fehlt nur noch das Höschen mh? 😏 Schreib mir schnell den Code SLIP und ich lasse auch das verschwinden 💋' : 'My tits are already waiting for you to be touched 😈 Now the only thing that bothers yet is the panties, huh? 😏 Write me the code STRING quickly and I\'ll make that disappear too 💋',
						"from": 1,
						"to": -1,
						"created_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"read_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"id": (new Date()).getTime()
					});
				}, 1900);
			}
			if (this.content.toLowerCase().indexOf("slip") > -1 || this.content.toLowerCase().indexOf("string") > -1) {
                window.jQuery.post('/loggedoutMessagesCodeUsed', {code: this.content.toLowerCase().indexOf("slip") > -1 ? 'slip' : 'string'});
				setTimeout(() => {
					this.startTypingIndicator();
				}, 400);
				setTimeout(() => {
					this.stopTypingIndicator();
					var attachment = {"type":"image","censored":true,"url":"https://chat-attachments.leonie-pur.com/attachments/15b78fdefd8f70a357d6665e1f7dbe05.jpg","thumburl":"https://chat-attachments.leonie-pur.com/attachments/15b78fdefd8f70a357d6665e1f7dbe05.jpg"};
					Event.$emit("added_message", {
						"attachments": [attachment],
						"content": this.lang.code == 'de' ? 'Das perfekte Outfit für eine heiße Nummer, oder? 😜 Willst du noch mehr sehen? Ich könnte meine Beine spreizen und dir mehr von meiner süßen Pussy zeigen 😇 Du brauchst nur PUSSY schreiben 😘' : 'The perfect outfit for naughty fun, right? 😜 Want to see more? I could spread my legs and show you more of my sweet pussy 😇 All you have to do is write PUSSY 😘',
						"from": 1,
						"to": -1,
						"created_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"read_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"id": (new Date()).getTime()
					});
				}, 1900);
			}
			if (this.content.toLowerCase().indexOf("pussy") > -1) {
                window.jQuery.post('/loggedoutMessagesCodeUsed', {code: 'pussy'});
				setTimeout(() => {
					this.startTypingIndicator();
				}, 400);
				setTimeout(() => {
					this.stopTypingIndicator();
					var attachment = {"type":"image","censored":true,"url":"https://chat-attachments.leonie-pur.com/attachments/d92fd6574b3ee63968e6e875cc3a57ab.jpg","thumburl":"https://chat-attachments.leonie-pur.com/attachments/d92fd6574b3ee63968e6e875cc3a57ab.jpg"};
					Event.$emit("added_message", {
						"attachments": [attachment],
						"content": this.lang.code == 'de' ? 'Sieh dir meine süße, enge Muschi an, die ist perfekt für dich und bereit für jeden Spaß. Als Krönung könntest du mich jetzt schön von hinten vernaschen. Willst du? 😈 Dann schreib mir noch einen allerletzten Code DOGGY und ich knie mich vor dich 💋' : 'Check out my sweet tight pussy, it\'s perfect for you and ready for any fun. To top it off, you could fuck me from behind. Do you want? 😈 Then write me one last code DOGGY and I\'ll kneel before you 💋',
						"from": 1,
						"to": -1,
						"created_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"read_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"id": (new Date()).getTime()
					});
				}, 1900);
			}
			if (this.content.toLowerCase().indexOf("doggy") > -1) {
                window.jQuery.post('/loggedoutMessagesCodeUsed', {code: 'doggy'});
				setTimeout(() => {
					this.startTypingIndicator();
				}, 400);
				setTimeout(() => {
					this.stopTypingIndicator();
					var attachment = {"type":"image","censored":true,"url":"https://chat-attachments.leonie-pur.com/attachments/dab4c7964d145d95fa0e88947b05e238.jpg","thumburl":"https://chat-attachments.leonie-pur.com/attachments/dab4c7964d145d95fa0e88947b05e238.jpg"};
					Event.$emit("added_message", {
						"attachments": [attachment],
						"content": this.lang.code == 'de' ? 'Stell dir vor, wie du deinen Schwanz jetzt in meine süße, enge Pussy drückst 😈 Oder nimmst du dir direkt meinen heißen Popo vor? 😇 ' : 'Imagine how you are now pushing your cock into my sweet, tight pussy 😈 Or are you going straight for my hot ass? 😇',
						"from": 1,
						"to": -1,
						"created_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"read_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"id": (new Date()).getTime()
					});
				}, 1900);

				setTimeout(() => {
					this.startTypingIndicator();
				}, 2300);

				setTimeout(() => {
					this.stopTypingIndicator();
					Event.$emit("added_message", {
						"attachments": [],
						"content": this.lang.code == 'de' ? 'Na toll, jetzt bist nicht nur du scharf geworden 🙈 Ich glaube, mein Höschen ist ein bisschen feucht. Pass auf, ich schenke dir eins meiner versauten Videos und dann schreibst du mir was du geiles mit mir anstellen würdest. Melde dich einfach fix an, damit du es dir anschauen kannst <a href="#signup" onclick="lpcms.show_modal(\'signup\');">—> Zum Video 🔥</a>' : 'Great, now you\'re not the only one who\'s turned on 🙈 I think my panties are a bit wet. How about I give you one of my dirty videos for free and then you write me what you would do with me. Just sign up quickly so you can watch it <a href="#signup" onclick="lpcms.show_modal(\'signup\');">—> To the video 🔥</a>',
						"from": 1,
						"to": -1,
						"created_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"read_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
						"id": (new Date()).getTime()
					});
				}, 5900);
			}
            this.content = null;
            this.isSending = false;
		}
	}
};
</script>
<template>
    <div class="card-body msg_card_body">
        <chat-message-component
			v-for="(message, index) in messages"
            :key="message.id"
            :message="message"
            :lastmessage_at="messages[index-1] ? messages[index-1].created_at : null"
            :lastmessage_from="messages[index-1] ? messages[index-1].from : null"
            ></chat-message-component>
        <div class="typing-indicator" v-if="showTypingIndicator">
            Leonie {{ lang.common.is_typing }}{{ dots }}
        </div>
    </div>
</template>

<script>
    import Event from "../event.js";
    export default {
        data() {
            return {
                messages: [],
                lastScrollPos: 0,
                lastScrollOffsetBottom: 0,
                isLoadingPrevMsgs: false,
                showTypingIndicator: false,
                dots: '',
                lang: window.Laravel.lang,
            }
        },
        mounted() {
            this.getMessages();
            Event.$on('added_message', (message) => {
                if (message.to == -1 && this.messages.length > 0 && this.messages.find(m => m.content == message.content)) {
                    return;
                }
                this.messages.push(message);
                if (message.attachments && message.attachments.length > 0) {
                    for (var i = 0; i < message.attachments.length; i++) {
                        Event.$emit('received_attachment', message.attachments[i]);
                    }
                }
                window.jQuery.post('/loggedoutMessages', {messages: JSON.stringify(this.messages)});
                this.scrollToBottom((message.attachments && message.attachments.length > 0 ? 600 : 100));
            });
            Event.$on('start_typing_indicator', () => {
                this.showTypingIndicator = true;
                this.scrollToBottom();
            });
            Event.$on('stop_typing_indicator', () => {
                this.showTypingIndicator = false;
            });
            setInterval(() => {
                this.dots = this.dots.length < 3 ? this.dots + '.' : '';
            }, 220);
        },
        methods: {
            scrollToBottom(timeout) {
                var container = this.$el;
                setTimeout(function(){
                    container.scrollTop = container.scrollHeight;
                }, timeout);
            },
            getMessages() {
                let resp = window.jQuery.ajax({
                    async: false,
                    url: '/loggedoutMessages',
                    type: 'GET',
                    dataType: 'json',
                });
                if (resp && resp.responseJSON && resp.responseJSON) {
                    this.messages = resp.responseJSON;
                    this.scrollToBottom(100);
                } else {
                    var attachment = {"type":"image","url":"https://chat-attachments.leonie-pur.com/attachments/f80b1e3fd8b53b760bdbbc19fe684067.jpg","thumburl":"https://chat-attachments.leonie-pur.com/attachments/f80b1e3fd8b53b760bdbbc19fe684067.jpg"};
                    this.messages = [
                        {
                            "blur": null,
                            "type": null,
                            "attachments": [attachment],
                            "content": this.lang.code == 'de' ? 'Hey, soll ich mich für dich ausziehen? Schreib mir jetzt hier den Code STRIP in den Chat und ich ziehe mich langsam für dich aus 😜' : 'Hey, do you want me to undress for you? Write me here the code STRIP in the chat now and I\'ll slowly undress for you 😜',
                            "from": 1,
                            "to": -1,
                            "created_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
                            "read_at": (new Date()).toISOString().slice(0, 19).replace('T', ' '),
                            "id": 1
                        }
                    ];
                }
            },
        }
    }
</script>
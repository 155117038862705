<template>
	<div>
		<div
		 	v-for="attachment in message.attachments"
			v-bind:key="attachment.url"
			v-bind:class="['chat-message', { 'from-me': current_user.id == message.from }, { 'not-from-me': current_user.id != message.from } ]"
		>
			<div v-if="!lastmessage_at || (new Date(message.created_at.replace(' ', 'T'))).getDay() != (new Date(lastmessage_at.replace(' ', 'T'))).getDay()" class="msg_timebreaker">
				{{ formatMessageBreaker(message.created_at) }}
			</div>
			<div class="msg_outer">
				<div v-if="current_user.id != message.from" v-bind:class="['img_cont_msg', { 'invisible': message.from == lastmessage_from }]"></div>
				<chat-message-attachment-component :from="message.from" :attachment="attachment" :msgcreated="message.created_at" />
			</div>
			<div class="clearer"></div>
		</div>
		<div v-bind:class="['video_recommendation_image', 'chat-message', { 'from-me': current_user.id == message.from }, { 'not-from-me': current_user.id != message.from } ]" v-if="message.video_recommendation">
			<div v-if="!lastmessage_at || (new Date(message.created_at.replace(' ', 'T'))).getDay() != (new Date(lastmessage_at.replace(' ', 'T'))).getDay()" class="msg_timebreaker">
				{{ formatMessageBreaker(message.created_at) }}
			</div>
			<div class="msg_outer">
				<div v-if="current_user.id != message.from" v-bind:class="['img_cont_msg', { 'invisible': message.from == lastmessage_from }]"></div>
				<div class="msg_wrapper msg_media">
					<div class="msg_container">
						<a :href="message.video_recommendation.url">
							<img :src="'https://cdn.leonie-pur.com'+message.video_recommendation.image" />
							<span class="material-symbols-rounded">play_arrow</span>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div
			v-bind:class="['chat-message', { 'from-me': current_user.id == message.from }, { 'not-from-me': current_user.id != message.from } ]"
		>
			<div v-if="(!message.attachments || message.attachments.length == 0) && !message.video_recommendation && (!lastmessage_at || (new Date(message.created_at.replace(' ', 'T'))).getDay() != (new Date(lastmessage_at.replace(' ', 'T'))).getDay())" class="msg_timebreaker">
				{{ formatMessageBreaker(message.created_at) }}
			</div>
			<div class="msg_outer">
				<div v-if="current_user.id != message.from" v-bind:class="['img_cont_msg', { 'invisible': message.from == lastmessage_from || message.video_recommendation }]"></div>
				<div class="msg_wrapper" v-if="message.content != ''">
					<div v-bind:class="['msg_container', { 'send': current_user.id == message.from } ]" v-if="typeof message.content == 'string'">
						<div v-html="message.content"></div>
						<span class="msg_time">{{ formatMessageTime(message.created_at) }}</span>
					</div>
				</div>
			</div>
			<div class="clearer"></div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["message", "lastmessage_at", "lastmessage_from", "Laravel"],
	data() {
		return {
			current_user: window.Laravel.user,
		};
	},
	mounted: function() {
		this.$nextTick(function () {
			$('.video-js', this.$el).each(function(i,v) {
				var player = videojs(v, {
					controlBar: {
						children: [
							'playToggle',
							'progressControl',
							'volumePanel',
							'fullscreenToggle',
						]
					},
				});
				player.on('fullscreenchange', function() {
					setTimeout(function() {
						var ctime = player.currentTime();
						player.currentTime(0);
						player.currentTime(ctime);
					}, 200);
				});
			});
		});
		if (typeof this.message.content == "string") {
			if (!this.message.content.match(/<a/)) {
				this.message.content = this.message.content.replace(/\n/g,"<br>").replace(/(https:\/\/[^\s]+)/g, "<a href='$1'>$1</a>");
			}
			let matches = this.message.content.match(/\{"video_recommendation":\{"id":(\d+),"title":"(.+?)","url":"(.+?)","excerpt":"(.+?)","thumb_url":"(.+?)"\}\}/);
			if (matches) {
				this.message.content = this.message.content.replace(matches[0], '<div class="video_recommendation"><a href="'+matches[3]+'"><span class="video_recommendation_title">'+matches[2]+'</span><span class="video_recommendation_excerpt">'+matches[4]+'</span></a><a href="'+matches[3]+'" class="videolink">'+window.Laravel.lang.common.video_recommendation_link_text+'</a></div>');
				this.message.video_recommendation = {
					id: matches[1],
					title: matches[2],
					url: matches[3],
					excerpt: matches[4],
					image: matches[5],
				};
			}
		}
	},
	methods: {
		formatMessageBreaker(time) {
			// + ", " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2)+' Uhr'
			var d = new Date(time.replace(' ', 'T')+'Z');
			return ("0" + d.getUTCDate()).slice(-2) + "." + ("0"+(d.getUTCMonth()+1)).slice(-2) + "." + d.getUTCFullYear();
		},
		formatMessageTime(time) {
			var d = new Date(time.replace(' ', 'T')+'Z');
			return ("0" + d.getUTCHours()).slice(-2) + ":" + ("0" + d.getUTCMinutes()).slice(-2);
		}
	}
};
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.message.attachments, function (attachment) {
        return _c(
          "div",
          {
            key: attachment.url,
            class: [
              "chat-message",
              { "from-me": _vm.current_user.id == _vm.message.from },
              { "not-from-me": _vm.current_user.id != _vm.message.from },
            ],
          },
          [
            !_vm.lastmessage_at ||
            new Date(_vm.message.created_at.replace(" ", "T")).getDay() !=
              new Date(_vm.lastmessage_at.replace(" ", "T")).getDay()
              ? _c("div", { staticClass: "msg_timebreaker" }, [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.formatMessageBreaker(_vm.message.created_at)) +
                      "\n\t\t"
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "msg_outer" },
              [
                _vm.current_user.id != _vm.message.from
                  ? _c("div", {
                      class: [
                        "img_cont_msg",
                        { invisible: _vm.message.from == _vm.lastmessage_from },
                      ],
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("chat-message-attachment-component", {
                  attrs: {
                    from: _vm.message.from,
                    attachment: attachment,
                    msgcreated: _vm.message.created_at,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "clearer" }),
          ]
        )
      }),
      _vm._v(" "),
      _vm.message.video_recommendation
        ? _c(
            "div",
            {
              class: [
                "video_recommendation_image",
                "chat-message",
                { "from-me": _vm.current_user.id == _vm.message.from },
                { "not-from-me": _vm.current_user.id != _vm.message.from },
              ],
            },
            [
              !_vm.lastmessage_at ||
              new Date(_vm.message.created_at.replace(" ", "T")).getDay() !=
                new Date(_vm.lastmessage_at.replace(" ", "T")).getDay()
                ? _c("div", { staticClass: "msg_timebreaker" }, [
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(
                          _vm.formatMessageBreaker(_vm.message.created_at)
                        ) +
                        "\n\t\t"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "msg_outer" }, [
                _vm.current_user.id != _vm.message.from
                  ? _c("div", {
                      class: [
                        "img_cont_msg",
                        { invisible: _vm.message.from == _vm.lastmessage_from },
                      ],
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "msg_wrapper msg_media" }, [
                  _c("div", { staticClass: "msg_container" }, [
                    _c(
                      "a",
                      { attrs: { href: _vm.message.video_recommendation.url } },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              "https://cdn.leonie-pur.com" +
                              _vm.message.video_recommendation.image,
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "material-symbols-rounded" },
                          [_vm._v("play_arrow")]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "chat-message",
            { "from-me": _vm.current_user.id == _vm.message.from },
            { "not-from-me": _vm.current_user.id != _vm.message.from },
          ],
        },
        [
          (!_vm.message.attachments || _vm.message.attachments.length == 0) &&
          !_vm.message.video_recommendation &&
          (!_vm.lastmessage_at ||
            new Date(_vm.message.created_at.replace(" ", "T")).getDay() !=
              new Date(_vm.lastmessage_at.replace(" ", "T")).getDay())
            ? _c("div", { staticClass: "msg_timebreaker" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.formatMessageBreaker(_vm.message.created_at)) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "msg_outer" }, [
            _vm.current_user.id != _vm.message.from
              ? _c("div", {
                  class: [
                    "img_cont_msg",
                    {
                      invisible:
                        _vm.message.from == _vm.lastmessage_from ||
                        _vm.message.video_recommendation,
                    },
                  ],
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.message.content != ""
              ? _c("div", { staticClass: "msg_wrapper" }, [
                  typeof _vm.message.content == "string"
                    ? _c(
                        "div",
                        {
                          class: [
                            "msg_container",
                            { send: _vm.current_user.id == _vm.message.from },
                          ],
                        },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.message.content),
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "msg_time" }, [
                            _vm._v(
                              _vm._s(
                                _vm.formatMessageTime(_vm.message.created_at)
                              )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "clearer" }),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }